<template>
  <div class="storage">
    <transition name="bubble">
      <page-block class="storage-view__block">
        <main-card>
          <div class="addons__label medium-title">
            {{ $t('params') }}
          </div>
          <div class="addons">
            <div class="addons__item">
              <div class="addons__label standart-text">
                <label class="standart-title">
                  {{ $t('s3') }}
                </label>
              </div>
              <div class="addons__value standart-text">s3.rusonyxcloud.ru</div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-text">
                <label class="standart-title">
                  {{ $t('useSSL') }}
                </label>
              </div>
              <div class="addons__value standart-text">Да</div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-text">
                <label class="standart-title">
                  {{ $t('region') }}
                </label>
              </div>
              <div class="addons__value standart-text">ru-msk</div>
            </div>
          </div>
          <div class="addons__label medium-title">
            {{ $t('type') }}
          </div>
          <br />
          <div class="addons">
            <div class="addons__item">
              <div class="addons__label standart-text">{{ $t('container') }}</div>
              <div v-if="mode === 'base'" class="addons__value standart-text">
                {{ tariff.policy.IsPublic === true ? 'Публичный' : 'Приватный' }}
              </div>
              <div v-else class="addons__value standart-text">
                <div class=""></div>
                <div v-for="category of categories" :key="category.key" class="field-radiobutton">
                  <RadioButton
                    :id="category.key"
                    v-model="selectedCategory"
                    name="category"
                    :value="category"
                    class="addons__value-radio"
                  />
                  <label :for="category.key">{{ $t(category.name) }}</label>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div v-if="mode === 'edit'" class="addons">
            <div class="addons__item">
              <checkbox-block
                :hint="$t('descr')"
                :config="config"
                :label="$t('all')"
                @change="onChange('check', $event)"
              >
              </checkbox-block>
            </div>
          </div>

          <div class="addons">
            <div v-if="mode === 'base'" class="addons__item">
              <div class="addons__item-btn">
                <Button
                  type="button"
                  label="Изменить"
                  icon="pi pi-pencil"
                  class="p-button-primary"
                  style="width: 130px"
                  @click="edit"
                ></Button>
              </div>
            </div>
            <div v-else class="addons__item">
              <div class="addons__item-btn">
                <Button
                  type="button"
                  label="Сохранить"
                  :disabled="!isChanged"
                  icon="pi pi-check"
                  class="p-button-primary"
                  style="width: 130px"
                  @click="send"
                ></Button>
              </div>
              <div class="addons__item-btn">
                <Button
                  type="button"
                  label="Отмена"
                  icon="pi pi-times"
                  style="width: 130px"
                  class="p-button-danger"
                  @click="cancel"
                ></Button>
              </div>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import RadioButton from 'primevue/radiobutton';
import { OPStorage } from '@/models/OP/OPStorage';
import Button from 'primevue/button';
import modals from '@/mixins/modals';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import CheckboxBlock from '@/components/Configurator/components/CheckboxBlock';
export default {
  name: 'ObjectsView',
  components: {
    MainCard,
    CheckboxBlock,
    RadioButton,
    Button,
  },
  mixins: [modals, showErrorOpenStackModal],
  props: {
    tariff: {
      type: OPStorage,
      required: true,
      validator: obj => obj instanceof OPStorage,
    },
  },
  data() {
    return {
      mode: 'base',
      config: {
        values: {
          on: true,
          off: false,
        },
        value: 'off',
        trueValue: 'on',
        falseValue: 'off',
      },
      selectedCategory: null,
      currentSize: '',
      currentCount: '',
      categories: [
        { name: 'private', key: 'private' },
        { name: 'public', key: 'public-read' },
      ],
      formData: {},
    };
  },
  computed: {
    isPublic() {
      return this.tariff.policy.IsPublic === true;
    },
    id() {
      return this.$store.state.moduleStack.current;
    },
    currentType() {
      return this.selectedCategory.name === 'public';
    },
    addAclToAllObjectStorage() {
      return this.formData.check.value === 'on';
    },
    isChanged() {
      return this.currentType !== this.isPublic || this.config.value !== 'off';
    },
    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
    },
  },
  watch: {
    tariff: {
      handler: function (event) {
        if (event) {
          this.selectedCategory =
            event.policy.IsPublic === true
              ? { key: 'public-read', name: 'public' }
              : { key: 'private', name: 'private' };
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.updateFormData(this.tariff);
  },
  methods: {
    updateFormData(event) {
      if (event) {
        this.selectedCategory =
          event.policy.IsPublic === true
            ? { key: 'public-read', name: 'public' }
            : { key: 'private', name: 'private' };
      }
    },
    edit() {
      this.mode = 'edit';
    },
    cancel() {
      this.mode = 'base';
      this.selectedCategory =
        this.tariff.policy.IsPublic === true
          ? { key: 'public-read', name: 'public' }
          : { key: 'private', name: 'private' };
    },
    onChange(name, event) {
      // console.log(name, event);
      this.formData[name] = event;
    },
    async send() {
      this.mode = 'base';
      this.config.value = 'off';
      const params = {
        name: this.tariff.name,
        policy: this.selectedCategory.key,
        // key: this.s3key,
      };
      // console.log(params);
      if (!this.addAclToAllObjectStorage)
        await this.$store
          .dispatch('moduleStack/setACLS3Bucket', params)
          .then(async () => {
            await this.$store.dispatch('moduleStack/fetchProjectStorages');
          })
          .catch(e => this.showErrorS3(e));
      else {
        await this.$store
          .dispatch('moduleStack/setACLS3Bucket', params)
          .then(async data => {
            // console.log(data);
            this.tariff.objects.forEach(x => {
              // console.log(x);
              const payload = {
                name: this.tariff.name,
                obj: x.key,
                policy: this.selectedCategory.key,
              };
              this.$store
                .dispatch('moduleStack/setACLObject', payload)
                .catch(e => console.error(e));
            });
            await this.$store.dispatch('moduleStack/fetchProjectStorages');
          })
          .catch(e => this.showErrorS3(e));
      }
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "all": "Применить к содержимому контейнера",
      "name": "Имя",
      "s3": "URL-адрес конечной точки объектного хранилища (S3 endpoint URL):",
      "useSSL": "Использование SSL для защищенного соединения:",
      "region": "Регион вашего облачного хранилища:",
      "params": "Параметры объектного хранилища",
      "descr": "Применить ко всем вложенным объектам контейнера.",
      "cache": "Кэширование",
      "container": "Тип контейнера",
      "type": "Настройки конфиденциальности",
      "x-container-meta-quota-bytes": "Суммарный размер, ГБ",
      "x-container-meta-quota-count": "Количество, шт",
"text": "Для сброса лимита - уберите символы из соответствующих полей. Настройки лимитов применяются только при загрузке новых файлов и не влияют на уже хранящиеся файлы.",
      "sum": "Суммарный размер, ГБ",
      "currentSize": "Текущий объем контейнера",
      "currentCount": "Текущее количество объектов",
      "private": "Приватный",
      "public": "Публичный",
      "count": "Количество, шт",
      "limit": "Лимиты объектов контейнера",
      "port": "Входящий порт",
      "vppr": "Служебные порты",
      "cpu": "vCPU",
      "ram": "Память",
      "disk": "Локальный диск",
      "networkDisk": "Сетевой диск"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.storage {
  max-width: 50rem
}
.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 130px;
      margin: 0.5em 0.75rem;
    }
  }
}
.field-radiobutton {
  margin-right: 1rem
}
.addons {

  &__input{
   //min-width: 20rem
    height: 36px;
   width: 100%
  }
  &__value{
    min-width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;




  &-radio {
      margin-right: 0.5rem
    }
  }

  &__item {
    //margin-top: 1rem;
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
      height: 45px;
    }

    &-btn {
      //min-width: 160px;
      width: 130px;
      margin-top: 1rem
      //margin: 0.5em 0.75rem;

      & + & {
        margin-left: 2rem
      }
    }

    & + & {
      //margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: -0.8rem;
        //margin-bottom: 0.4rem;
        //padding-top: 0.4rem;
        //padding-bottom: 1.4rem;
        //border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 32rem;
      margin-right: 0.5rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}
</style>
