<template>
  <div class="subnet">
    <div class="subnet-info">
      <div class="subnet-info__create">
        <base-button
          :icon="isIcon"
          :tooltip="{
            content: text,
            autoHide: false,
            placement: 'auto',
            container: false,
            trigger: 'click hover',
          }"
          :color="isColor"
          class="subnet-info__create-btn"
          @click="isSubnets !== 0 ? newSubnet(tariff) : ''"
          >{{ $t('newSubnet') }}</base-button
        >
      </div>
    </div>
    <transition name="bubble">
      <page-block class="table">
        <tariffs-table-private :table-head="tableHead" :dataset="subnets" />
      </page-block>
    </transition>
  </div>
</template>

<script>
import { OPTariff } from '@/models/OP/OPTariff';
import TariffsTablePrivate from '@/layouts/Stack/pages/Main/components/TariffsTablePrivate';
import newSubnet from '@/mixins/newSubnet';
export default {
  name: 'SubnetView',
  components: {
    TariffsTablePrivate,
  },
  mixins: [newSubnet],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'address',
          label: this.$t('tableHead.address'),
          style: {
            width: '150px',
            maxWidth: '260px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        {
          key: 'dns',
          label: this.$t('tableHead.dns'),
          style: {
            width: '150px',
            maxWidth: '260px',
          },
        },
        {
          key: 'pools',
          label: this.$t('tableHead.pools'),
          style: {
            width: '200px',
            maxWidth: '260px',
          },
        },
        {
          key: 'dhcp',
          label: this.$t('tableHead.dhcp'),
          style: {
            width: '44px',
          },
        },
        {
          key: 'gateway',
          label: this.$t('tableHead.gateway'),
          style: {
            width: '124px',
          },
          sort: {
            prop: 'gateway_ip',
            order: 'asc',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '44px',
            textAlign: 'center',
          },
        },
      ],
      // isLoading: true,
    };
  },
  computed: {
    isIcon() {
      return this.isSubnets === 0 ? 'error' : '';
    },
    isColor() {
      return this.isSubnets === 0 ? 'warn' : 'primary';
    },
    text() {
      if (this.isSubnets === 0) {
        return `Вы достигли квоты по количеству подсетей.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
      } else {
        return 'Создать новую приватную сеть';
      }
    },
    subnetsId() {
      return this.tariff.subnets;
    },
    isSubnets() {
      if (
        this.$store.state.moduleStack.quotasNetwork &&
        this.$store.state.moduleStack.quotasNetwork.subnet
      ) {
        return (
          this.$store.state.moduleStack.quotasNetwork.subnet.limit -
          this.$store.state.moduleStack.quotasNetwork.subnet.used
        );
      } else return 0;
    },
    subnets() {
      return this.$store.getters['moduleStack/GET_SORTED_LIST_SUBNET'].filter(
        x => x.network_id === this.tariff.id
      );
    },
    diskId() {
      return this.tariff.id;
    },
  },
  methods: {
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "tableHead": {
        "name": "Имя",
        "address": "Адрес",
        "dns": "DNS-серверы",
        "pools": "Пул IP-адресов",
        "ip": "Версия",
        "gateway": "Шлюз",
        "dhcp": "DHCP",
        "config": "Конфигурация",
        "status": "Статус",
        "subnet": "Подсеть",
        "menu": "Меню"
      },
      "attach": "Подключен",
      "quotaSubnet": "Вы достигли квоты по количеству подсетей, обратитесь в поддержку для изменения квоты",
      "size": "Размер",
      "type": "Тип диска",
      "os": "Источник",
      "name": "Тип конфигурации",
      "cpu": "vCPU",
      "newSubnet": "Новая подсеть"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.table {
  margin-top: 0.5rem;
}
.subnet {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }
    &__create {
      display: flex;
      flex-direction row;
      align-content flex-end;
      align-items baseline;
      margin-top: 1.5rem;
      max-width: 200px;
      &-btn {
        margin-top: 20px
        margin-right: 0;
        +breakpoint(ms-and-up) {
          margin-top: 20px;
        }
      }
      &-hint {
        margin: -0.25rem 0.25rem;
        vertical-align: middle;
      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

}
</style>
