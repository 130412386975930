export default {
  methods: {
    showError(e, name) {
      let error;
      if (name === 'allocationPools') {
        error = e;
      } else
        error =
          // e?.response?.data &&
          // e?.response?.data?.['NeutronError'] &&
          e?.response?.data['NeutronError']?.message
            ? e.response.data['NeutronError'].message
            : e.message;
      const errorParsingText = e?.config?.data ? JSON.parse(e.config.data) : '';
      const ruError = error.includes('overlaps with another subnet')
        ? `Подсеть c CIDR <b>${errorParsingText?.subnet?.cidr}</b> в сети <b>${name}</b> пересекается с другой подсетью.`
        : error;
      // const title = e.response.data['NeutronError'].type;
      // console.log(e);
      this.$modals.open({
        name: 'Error',
        // title: this.$t('error'),
        // title: title,
        html: true,
        text: ruError,
        footer: {
          centered: true,
          cancel: {
            props: { title: this.$t('close'), theme: 'filled' },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
    showErrorS3(e) {
      const error = e?.message ? e?.message : e;
      const title = e?.type;
      this.$modals.open({
        name: 'Error',
        // title: this.$t('error'),
        title: title,
        text: error,
        footer: {
          centered: true,
          confirm: {
            props: { title: this.$t('confirm') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
  },
};
